import nl from './nl';
import en from './en';

const lang = {
    nl,
    // TODO: Uncomment when english support is added
    en
};

export default lang;
